<!-- 单输入框组件 -->
<template>
  <div class="input_field-shade">
    <div class="input_field-box">
      <div class="input_field-close-box">
        <span class="input_field-hint">{{ message }}</span>
        <div class="input_field-close" @click="cancelSubmit">
          <span class="iconfont">&#xe65d;</span>
        </div>
      </div>
      <div class="input_field-message-box">
        <input ref="inputRef" class="input_field-input" v-model="inputDate" type="text" @keydown.enter="confirmSubmit" />
      </div>
      <div class="input_field-button-box">
        <button class="input_field-cancel" @click="cancelSubmit">取消</button>
        <button class="input_field-affirm" @click="confirmSubmit">确定</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    inject,
    onMounted,
    defineProps,
    defineEmits
  } from 'vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    inputRef.value.focus();
  });

  // 常量
  const emits = defineEmits();
  const commonUtils = inject('$commonUtils');
  const props = defineProps({
    message: {
      type: String,
      required: true
    }
  });

  // 变量
  let inputRef = ref(null);
  let inputDate = ref(null);

  /**
   * 将输入值提交到父类
   */
  function confirmSubmit() {
    if (inputDate.value) {
      emits('inputEmits', {
        judeg: true,
        data: inputDate.value
      });
    } else {
      commonUtils.elmentMessage('格式不为空', 'warning', 30);
    }
  }

  /**
   * 关闭组件
   */
  function cancelSubmit() {
    emits('inputEmits', {
      judeg: false
    });
  }
</script>

<style scoped>
  .input_field-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 39, 46, 0.5);
    z-index: 2000;
  }

  .input_field-box {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 300px;
    height: 180px;
    background-color: white;
    box-shadow: 0 0 15px rgba(53, 59, 72, 0.5);
  }

  .input_field-close-box {
    height: 20%;
    display: flex;
    align-items: center;
    background-color: rgba(53, 59, 72, 1.0);
  }

  .input_field-hint {
    max-width: 70%;
    user-select: none;
    color: white;
    font-size: 15px;
    margin-left: 20px;
    color: rgba(220, 221, 225, 0.8);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .input_field-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .input_field-close:active {
    background-color: rgba(220, 221, 225, 0.2);
  }

  .input_field-close span {
    user-select: none;
    font-size: 17px;
    color: rgba(241, 242, 246,0.9);
  }

  .input_field-message-box {
    user-select: none;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input_field-input {
    outline: none;
    width: 80%;
    height: 20px;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid gray;
  }

  .input_field-input:focus {
    border: 1px solid black;
  }

  .input_field-button-box {
    user-select: none;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .input_field-button-box button {
    width: 35%;
    height: 70%;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .input_field-affirm {
    border: none;
    background-color: rgba(0, 168, 255, 0.8);
    color: white;
  }

  .input_field-affirm:active {
    background-color: rgba(0, 168, 255, 0.5);
  }

  .input_field-cancel {
    border: none;
    background-color: rgba(225, 177, 44, 0.8);
    color: white;
  }

  .input_field-cancel:active {
    background-color: rgba(225, 177, 44, 0.5);
  }
</style>
