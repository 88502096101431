<!-- 响应式菜单组件 -->
<template>
  <div ref="shadeRef" class="dropdown-shade" @click="menuSwitch(true)" @mouseover="shiftinSuspensionOpen" @mouseleave="shiftoutSuspensionClose">
    <div ref="describeRef" class="dropdown-describe">{{ describe }}</div>
    <transition name="fade">
      <div ref="menuRef" class="dropdown-menuBar" v-if="menuBar">
        <span ref="pointRef" class="dropdown-point"></span>
        <slot name="menus"></slot>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import {
    ref,
    nextTick,
    defineProps,
    defineEmits
  } from 'vue';

  // 组件参数
  const props = defineProps({
    describe: {
      type: String,
      default: "•••",
      required: false
    },
    selector: {
      type: Object,
      required: false
    },
    control: {
      type: String,
      required: false
    },
    data: {
      type: Object,
      required: false
    }
  });

  // 变量
  let menuBar = ref(false);
  let shadeRef = ref(null);
  let menuRef = ref(null);
  let pointRef = ref(null);
  let describeRef = ref(null)
  let openTimeout = null;
  let closeTimeout = null;

  // 可指定参数， 菜单触发器样式，宽高， 打开延时， 关闭延时， 触发方式
  let op = (props.data === undefined || props.data.op) ?? 100;
  let cs = (props.data === undefined || props.data.cs) ?? 100;
  let control = props.control ?? "hover";
  let menuRefMinWidth = (props.data === undefined || props.data.width) ? "auto" : props.data.width;
  let menuRefMinHeight = (props.data === undefined || props.data.height) ? "auto" : props.data.height;

  /**
   * 鼠标移入打开菜单
   */
  function shiftinSuspensionOpen() {
    if (control === "hover") {
      clearTimeout(openTimeout)
      clearTimeout(closeTimeout)
      openTimeout = setTimeout(() => {
        menuBar.value = true
        menuSwitch(true)
      }, op)
    }
  }

  /**
   * 鼠标移出关闭菜单
   */
  function shiftoutSuspensionClose() {
    if (control === "hover") {
      clearTimeout(openTimeout)
      closeTimeout = setTimeout(() => {
        menuBar.value = false
        menuSwitch(false)
      }, cs)
    }
  }

  /**
   * 监听用户点击
   * @param {event} event
   */
  function clickSuspensionContor(event) {
    if (!shadeRef.value.contains(event.target)) { menuSwitch(false); }
  }

  /**
   * 菜单开关逻辑
   * @param {是否只控制关闭} judeg
   */
  function menuSwitch(judeg) {
    menuBar.value = control === "click" ? !menuBar.value : menuBar.value;
    if (menuBar.value && judeg) {
      nextTick(() => {
        updateDropdownPosition();
      });
      window.addEventListener('click', clickSuspensionContor);
      window.addEventListener('mousedown', clickSuspensionContor);
      window.addEventListener('touchstart', clickSuspensionContor);
      window.addEventListener('resize', updateDropdownPosition);
      if (props.selector !== undefined) {
        props.selector.addEventListener('scroll', updateDropdownPosition);
      }
      describeRef.value.style.color = "white"
    } else {
      window.removeEventListener('click', clickSuspensionContor);
      window.removeEventListener('mousedown', clickSuspensionContor);
      window.removeEventListener('touchstart', clickSuspensionContor);
      window.removeEventListener('resize', updateDropdownPosition);
      if (props.selector !== undefined) {
        props.selector.removeEventListener('scroll', updateDropdownPosition);
      }
      describeRef.value.style.color = null
    }
  }


  /**
   * 根据父类位置创建菜单栏
   */
  function updateDropdownPosition() {
    requestAnimationFrame(() => {
      // 系统变量
      let scrollX = window.scrollX;
      let scrollY = window.scrollY;
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      let triggerRect = shadeRef.value.getBoundingClientRect();
      // 菜单栏
      let menuRefLeft = triggerRect.right + scrollX + 5;
      let menuRefTop = triggerRect.bottom + scrollY + 5;
      // 指示标
      let pointorderRadius = "0 8px 8px 8px";
      let pointRefLeft = 0;
      let pointRefRight = "auto";
      let pointRefTop = 0;
      let pointRefBottom = "auto";
      let pointRefBorderLeft = 0;
      let pointRefBorderRight = "12px solid transparent";
      let pointRefBorderTop = "12px solid white";
      let pointRefBorderBottom = 0;
      let around = false
      // 判断最终插入位置
      if (menuRefLeft + menuRef.value.offsetWidth > windowWidth + scrollX) {
        around = true
        pointorderRadius = "8px 0 8px 8px";
        pointRefLeft = "auto";
        pointRefRight = 0;
        pointRefBorderLeft = "12px solid transparent";
        pointRefBorderRight = 0
        menuRefLeft = triggerRect.left - menuRef.value.offsetWidth + scrollX - 5;
      }
      if (menuRefTop + menuRef.value.offsetHeight > windowHeight + scrollY) {
        pointorderRadius = around ? "8px 8px 0 8px" : "8px 8px 8px 0";
        pointRefTop = "auto";
        pointRefBottom = 0;
        pointRefBorderTop = 0;
        pointRefBorderBottom = "12px solid white";
        menuRefTop = triggerRect.top - menuRef.value.offsetHeight + scrollY - 5;
      }
      // 设置元素参数
      menuRef.value.style.minWidth = `${menuRefMinWidth}`;
      menuRef.value.style.minhHeight = `${menuRefMinHeight}`;
      menuRef.value.style.left = `${menuRefLeft}px`;
      menuRef.value.style.top = `${menuRefTop}px`;
      menuRef.value.style.borderRadius = `${pointorderRadius}`;
      pointRef.value.style.left = `${pointRefLeft}`;
      pointRef.value.style.right = `${pointRefRight}`;
      pointRef.value.style.top = `${pointRefTop}`;
      pointRef.value.style.bottom = `${pointRefBottom}`;
      pointRef.value.style.borderLeft = `${pointRefBorderLeft}`;
      pointRef.value.style.borderRight = `${pointRefBorderRight}`;
      pointRef.value.style.borderTop = `${pointRefBorderTop}`;
      pointRef.value.style.borderBottom = `${pointRefBorderBottom}`;
      // 插入元素
      document.body.appendChild(menuRef.value);
      if (control === "hover") {
        menuRef.value.addEventListener('mouseover', shiftinSuspensionOpen)
        menuRef.value.addEventListener('mouseleave', shiftoutSuspensionClose)
      }
    });
  }
</script>

<style>
  .dropdown-shade {
    user-select: none;
    position: relative;
  }

  .dropdown-describe {
    cursor: pointer;
    color: white;
    color: rgba(206, 214, 224, 0.8);
    font-size: 10px;
    transition: color 0.3s ease-in-out;
  }

  .dropdown-describe:active {
    color: white;
  }

  .dropdown-menuBar {
    position: absolute;
    padding: 12px;
    color: white;
    z-index: 2000;
    background-color: black;
    box-shadow: 0 0 5px rgba(255, 63, 52, 0.5);
  }

  .dropdown-menuBar button {
    white-space: nowrap;
    width: 100%;
    height: 40px;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    background: none;
    color: white;
    border: none;
  }

 .dropdown-menuBar button::before {
     font-family: 'iconfont';
     font-size: 18px;
     margin-right: 10px;
   }

  .dropdown-menuBar .iconfont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dropdown-point {
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
