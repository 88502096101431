import {
  createRouter,
  createWebHistory
} from 'vue-router';
import { inject } from 'vue';
import Home from '@/views/Home.vue';
import Register from '@/views/Register.vue';
import Login from '@/views/Login.vue';
import ChatGpt from '@/views/ChatGpt.vue';
import PictureGeneration from '@/views/PictureGeneration.vue';
import Userinfo from '@/views/Userinfo.vue';
import Picture from '@/views/Picture.vue';

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Nuyoah', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { title: 'Register', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: false }
  },
  {
    path: '/chatgpt',
    name: 'ChatGpt',
    component: ChatGpt,
    meta: { title: 'ChatGpt', favicon: '/static/images/logo/chatgpt_logo.png', showHeader: false }
  },
  {
    path: '/picture_generation',
    name: 'PictureGeneration',
    component: PictureGeneration,
    meta: { title: 'PictureGeneration', favicon: '/static/images/logo/chatgpt_logo.png', showHeader: false }
  },
  {
    path: '/userinfo',
    name: 'Userinfo',
    component: Userinfo,
    meta: { title: 'Userinfo', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: false }
  }, {
    path: '/picture',
    name: 'Picture',
    component: Picture,
    meta: { title: 'Picture', favicon: '/static/images/logo/nuyoah_logo_black.png', showHeader: true }
  }
];

// 设置路由路径解析样式
const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes });
// 启动路由前设置标题与浏览器logo
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '';
  const favicon = document.querySelector('link[rel="icon"]');
  if (favicon) {
    favicon.href = to.meta.favicon || '';
  }
  // 登录后跳转需要记录的路由
  const reuterArr = ['/', '/chatgpt', '/picture_generation', '/userinfo'];
  // 保存指定上一页路由
  reuterArr.forEach((r) => {
    if (r === from.fullPath) {
      inject('$routePath').value = from.fullPath;
    }
  });
  next();
});

export default router;
