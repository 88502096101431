<template>
  <!-- 组件 -->
  <Message v-model:message="messageData" left="50%" top="40px"></Message>
  <Safety v-if="safetyControl" @safetyEmits="safetyMonitor"></Safety>
  <Select v-if="selectData" :data="selectData" @selectEmits="selectMonitor" fontSize="18px"></Select>
  <InputPwd v-if="inputPawControl" @inputPwdEmits="inputPwdMonitor"></InputPwd>
  <InputField v-if="inputFieldMessages" :message="inputFieldMessages" @inputEmits="inputMonitor"></InputField>
  <div class="userinfo-elment-mask" v-loading="loading" :element-loading-svg="svg" element-loading-svg-view-box="-10, -10, 50, 50" style="width: 100%"></div>
  <div class="userinfo_mask" v-if="mask" @click="menuleftSwitch(false)"></div>
  <div class="userinfo_base">
    <!-- 左区域 -->
    <div class="userinfo-left-box" :style="leftBoxStyle">
      <!-- 头像昵称 -->
      <div class="userinfo-user-frame-box">
        <span class="iconfont userinfo-menu-close" v-if="menuLeftClose" @click="menuleftSwitch(true)">&#xe600;</span>
        <div class="userinfo-info-box">
          <div class="userinfo-info-case">
            <img :src="head" alt="" draggable="false">
            <p v-if="nickname">{{ nickname }}</p>
            <p v-if="!nickname" @click.stop="commonUtils.thisSkip(router, '/login')">登录</p>
          </div>
        </div>
      </div>
      <!-- 左侧菜单点击按钮 -->
      <div class="userinfo-menu-button-box">
        <div class="userinfo-menu-box">
          <div class="userin-menu-button-all">
            <button v-for="(item, index) in butAll" :style="item.active" type="button" @click="leftOperation(item, index)">{{ item.name }}</button>
          </div>
          <div class="userinfo-logout" v-if="userLoguot" @click.stop="commonUtils.userLogout">
            <el-icon>
              <SwitchButton />
            </el-icon>
            <p>注销登录</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 中间区域 -->
    <div class="userinfo-middle-box">
      <!-- 背景 -->
      <div class="userinfo-operation-box">
        <div class="userinfo-background-box" :style="user_background"></div>
        <!-- 背景上栏菜单 -->
        <div class="userinfo-background-top-menu-box">
          <span class="iconfont userinfo-menu-button" v-if="menuJudeg" @click.stop="menuleftSwitch(true)">&#xe63e;</span>
          <span class="iconfont userinfo-menu-button" @click="commonUtils.thisSkip(router, '/')">&#xe65e;</span>
        </div>
        <!-- 关注收藏粉丝 -->
        <div class="userinfo-info-data-box">
          <div class="userinfo-data-box">
            <div class="userinfo-data">
              <p>粉丝</p>
              <p>--</p>
            </div>
          </div>
          <div class="userinfo-data-box">
            <div class="userinfo-data">
              <p>关注</p>
              <p>--</p>
            </div>
          </div>
          <div class="userinfo-data-box">
            <div class="userinfo-data">
              <p>收藏</p>
              <p>--</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 账户信息展示区 -->
      <div class="userinfo-information-show" v-if="accountInfo">
        <div class="userinfo-information-p-box" v-if="nickname">
          <p class="userinfo-information-p1">用户名：</p>
          <p class="userinfo-information-p2">{{ nickname }}</p>
          <button class="userinfo-information-p-button" @click="inputFieldMessages = '用户名修改' , apiUrl = '/user/updata_nickname', privacy = 'nickname'">修改</button>
        </div>
        <div class="userinfo-information-p-box" v-if="sex">
          <p class="userinfo-information-p1">性别：</p>
          <p class="userinfo-information-p2">{{ sex }}</p>
          <button class="userinfo-information-p-button" @click="selectData = { title: '性别修改', fontSize: '18px', selAll: ['?', '男', '女'] }, apiUrl = '/user/update_sex'">修改</button>
        </div>
        <div class="userinfo-information-p-box" v-if="username">
          <p class="userinfo-information-p1">账号：</p>
          <p class="userinfo-information-p2">{{ username }}</p>
          <button class="userinfo-information-p-button" id="copyUnm" :data-clipboard-text="username" @click="copyText('#copyUnm')">复制</button>
        </div>
        <div class="userinfo-information-p-box" v-if="password">
          <p class="userinfo-information-p1">密码：</p>
          <p class="userinfo-information-p2">{{ password }}</p>
          <div class="userinfo-information-botton-box">
            <span class="iconfont userinfo-eye-switch" v-html="passwordEye" @click="viewShow(passwordEye, privacy = 'password')"></span>
            <el-dropdown :trigger="dropdownType">
              <span class="userinfo-el-dropdown-link">操作<el-icon class="el-icon--right"><arrow-down /></el-icon></span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :style="dropdownStyle" id="copy_password" v-if="jurisdictionTime" :data-clipboard-text="password" @click="copyText('#copy_password')">复制</el-dropdown-item>
                  <el-dropdown-item :style="dropdownStyle" @click="jurisdictionTime === null ? safetyControl = true : inputPawControl = true, apiUrl = '/user/update_password', privacy = 'password'">修改</el-dropdown-item>
                  <el-dropdown-item :style="dropdownStyle">忘记密码</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <!-- 作品管理展示页面 -->
      <div class="userinfo-information-show" v-if="productionInfo">

      </div>
      <!-- cgatgpt信息展示 -->
      <div class="userinfo-information-show" v-if="chatgptInfo">
        <div class="userinfo-information-p-box" v-if="apikey">
          <p class="userinfo-information-p1">Apikey：</p>
          <p class="userinfo-information-p2">{{ apikey }}</p>
          <div class="userinfo-information-botton-box">
            <span class="iconfont userinfo-eye-switch" v-if="apikey !== '未配置'" v-html="apikeyEye" @click="viewShow(apikeyEye, privacy = 'apikey')"></span>
            <el-dropdown :trigger="dropdownType">
              <span class="userinfo-el-dropdown-link">操作<el-icon class="el-icon--right"><arrow-down /></el-icon></span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :style="dropdownStyle" id="copy_apikey" v-if="jurisdictionTime" :data-clipboard-text="apikey" @click="copyText('#copy_apikey')">复制</el-dropdown-item>
                  <el-dropdown-item :style="dropdownStyle" @click="jurisdictionTime === null ? safetyControl = true : inputFieldMessages = '配置Apikey' , apiUrl = '/user/config_apikey', privacy = 'apikey'">配置</el-dropdown-item>
                  <el-dropdown-item :style="dropdownStyle">忘记密码</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- 右区域 -->
    <!-- 1120关闭 -->
    <div class="userinfo-right-box" v-if="false">

    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    nextTick
  } from 'vue';
  import { useRouter } from 'vue-router';
  import {
    SwitchButton,
    ArrowDown
  } from '@element-plus/icons-vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    userWhetherLogin();
    commonUtils.addPageIdentification(pageRandom);
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  // 常量
  const router = useRouter();
  const commonUtils = inject('$commonUtils');
  const axiosApi = inject('$axiosApi');
  const cookie = inject('$cookie');
  const resourceApi = inject('$resourceApi');
  const clipboard = inject('$clipboard');
  const AESUtils = inject('$AESUtils');
  const pageRandom = 'Userinfo-' + commonUtils.getRandomInteger(10) + commonUtils.getRandomString(5);
  const svg = '<path class="path" d="M 30 15 L 28 17 M 25.61 25.61 A 15 15, 0, 0, 1, 15 30 A 15 15, 0, 1, 1, 27.99 7.5 L 15 15 "style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>';

  // 变量
  let userLoguot = ref(false);
  let inputFieldMessages = ref(null);
  let device = commonUtils.equipmentJudeg();
  let windWidth = window.innerWidth;
  let dropdownType = ref(device === 'PC' ? 'hover' : 'click');
  let apiUrl = ref(null);
  let nickname = ref(null);
  let username = ref(null);
  let password = ref(null);
  let passwordStorage = null;
  let sex = ref(null);
  let apikey = ref(null);
  let apikeyStorage = null;
  let accountInfo = ref(true);
  let productionInfo = ref(false);
  let chatgptInfo = ref(false);
  let selectData = ref(null);
  let messageData = ref(null);
  let safetyControl = ref(false);
  let inputPawControl = ref(false);
  let passwordEye = ref('&#xe6fb;');
  let apikeyEye = ref('&#xe6fb;');
  let privacy = ref(null);
  let loading = ref(false);
  let menuJudeg = ref(false);
  let menuLeftClose = ref(false);
  let mask = ref(false);
  let resize = ref(windWidth >= 1120);
  let jurisdictionTime = ref(null);
  let head = ref(require('@/assets/image/system/user_head_default.png'));
  let dropdownStyle = ref({ 'display': 'flex', 'justify-content': 'center' });
  let leftBoxStyle = ref({ 'width': '250px', 'min-width': '250px', 'position': 'relative', 'transition': 'none' });
  let butAll = ref([
    { name: '账号信息', active: { 'color': 'red' }, index: 1 },
    { name: '作品管理', active: { 'color': null }, index: 2 },
    { name: 'ChatGpt配置', active: { 'color': null }, index: 3 }
  ]);
  let user_background = ref({
    'background': "url('" + require('@/assets/image/system/user_background_default.png') + "')",
    'background-size': 'cover',
    'background-position': 'center',
    'background-repeat': 'no-repeat'
  });

  /**
   * 页面刷新或关闭关闭时
   */
  function handleBeforeUnload() {
    commonUtils.removePageIdentification(pageRandom);
  }

  /**
   * 监听浏览器页面是否可视
   */
  function visualizationMonitor() {
    if (!document.hidden) {
      userWhetherLogin();
      commonUtils.addPageIdentification(pageRandom);
    }
  }

  /**
   * 页面大小变化
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    device = commonUtils.equipmentJudeg();
    pageInitialize();
  }

  /**
   * 页面初始化
   */
  function pageInitialize() {
    leftBoxStyle.value['transition'] = 'none';
    if (windWidth >= 1120 && resize.value) {
      resize.value = false;
      menuJudeg.value = false;
      menuLeftClose.value = false;
      mask.value = false;
      leftBoxStyle.value['width'] = '250px';
      leftBoxStyle.value['min-width'] = '250px';
      leftBoxStyle.value['position'] = 'relative';
    } else if (windWidth <= 1120 && !resize.value) {
      resize.value = true;
      menuJudeg.value = true;
      menuLeftClose.value = true;
      mask.value = false;
      leftBoxStyle.value['width'] = 0;
      leftBoxStyle.value['min-width'] = 0;
      leftBoxStyle.value['position'] = 'fixed';
    }
  }

  /**
   * 左侧栏开关
   * @param {是否只控制关闭} judeg
   */
  function menuleftSwitch(judeg) {
    if (leftBoxStyle.value.width === 0 && judeg) {
      mask.value = true;
      leftBoxStyle.value['width'] = '250px';
      leftBoxStyle.value['min-width'] = '250px';
    } else if(windWidth <= 1120){
      mask.value = false;
      leftBoxStyle.value['width'] = 0;
      leftBoxStyle.value['min-width'] = 0;
    }
    leftBoxStyle.value['transition'] = 'all 0.2s ease-in-out';
  }

  /**
   * 判断有无登录信息
   */
  function userWhetherLogin() {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (userinfo) {
      userLoguot.value = true;
      passwordStorage = null;
      apikeyStorage = null;
      head.value = resourceApi + userinfo.head_portrait;
      user_background.value.background = "url('" + resourceApi + userinfo.head_portrait + "')";
      nickname.value = userinfo.nickname;
      username.value = userinfo.username;
      password.value = '**********';
      passwordEye.value = '&#xe6fb;';
      sex.value = userinfo.sex;
      apikey.value = userinfo.apikey && userinfo.apikey !== '' ? userinfo.apikey : '未配置';
      apikeyEye.value = '&#xe6fb;';
    } else {
      userLoguot.value = false;
    }
  }

  /**
   * 左侧按钮操作设置
   * @param {数据集} item
   * @param {下标} index
   */
  function leftOperation(item, index) {
    menuleftSwitch(true);
    butAll.value.forEach(item => {
      item.active['color'] = null;
    });
    item.active['color'] = 'red';
    accountInfo.value = false;
    productionInfo.value = false;
    chatgptInfo.value = false;
    switch (item.index) {
      case 1:
        accountInfo.value = true;
        break;
      case 2:
        productionInfo.value = true;
        break;
      case 3:
        chatgptInfo.value = true;
        break;
    }
  }

  /**
   * 修改框点击结果回响接收
   * @param {组件返回} params
   */
  async function inputMonitor(params) {
    if (params.judeg) {
      // 请求修改
      const userinfo = commonUtils.emptyCookie('Userinfo', null, true);
      if (userinfo) {
        let safetyJudeg = true;
        loading.value = true;
        const formData = new FormData();
        formData.append('uid', userinfo.uid);
        switch (privacy.value) {
          case 'nickname':
            formData.append('price', params.data);
            break;
          case 'apikey':
            if (jurisdictionTime.value) {
              formData.append('username', userinfo.username);
              formData.append('apikey', await AESUtils.encrypt(params.data, 'stair'));
            } else {
              safetyJudeg = false;
            }
            break;
        }
        if (safetyJudeg) {
          axiosApi.routine(apiUrl.value, 'POST', true, formData, false)
            .then(result => {
              if (result === 1) {
                switch (privacy.value) {
                  case 'nickname':
                    nickname.value = params.data;
                    userinfo.nickname = params.data;
                    cookie.set('Userinfo', encodeURI(JSON.stringify(userinfo)));
                    break;
                  case 'apikey':
                    if (apikeyEye.value === '&#xe6f9;') {
                      apikeyStorage = apikey.value = params.data;
                    }
                    userinfo.apikey = commonUtils.substitution(5, params.data.length - 6, params.data, '*');
                    cookie.set('Userinfo', encodeURI(JSON.stringify(userinfo)));
                    break;
                }
                messageData.value = "修改成功";
                loading.value = false;
              } else {
                messageData.value = "修改失败";
                loading.value = false;
              }
            });
        } else {
          commonUtils.elmentMessage('安全验证已过期', 'warning', 30);
          loading.value = false;
        }
      }
    }
    inputFieldMessages.value = null;
    apiUrl.value = null;
  }

  /**
   * 处理选择框组件反馈
   * @param {组件返回} params
   */
  function selectMonitor(params) {
    if (params.judeg) {
      const userinfo = commonUtils.emptyCookie('Userinfo', null, true);
      if (userinfo) {
        loading.value = true;
        const formData = new FormData();
        formData.append('uid', userinfo.uid);
        formData.append('price', params.data);
        axiosApi.routine(apiUrl.value, 'POST', true, formData, false)
          .then(result => {
            if (result === 1) {
              sex.value = params.data;
              userinfo.sex = params.data;
              cookie.set('Userinfo', encodeURI(JSON.stringify(userinfo)));
              messageData.value = "修改成功";
              loading.value = false;
            } else {
              messageData.value = "修改失败";
              loading.value = false;
            }
          });
      }
    }
    selectData.value = null;
    apiUrl.value = null;
  }

  /**
   * 处理安全验证组件反馈
   * @param {组件返回} params
   */
  function safetyMonitor(params) {
    const userinfo = commonUtils.emptyCookie('Userinfo', null, true);
    if (params.judeg) {
      switch (privacy.value) {
        case 'password':
          pwd_axios(userinfo.uid).then(result => { passwordStorage = password.value = result; });
          passwordEye.value = '&#xe6f9;';
          break;
        case 'apikey':
          apikey_axios(userinfo.uid).then(result => { apikeyStorage = apikey.value = result; });
          apikeyEye.value = '&#xe6f9;';
          break;
      }
      jurisdictionTime.value = setTimeout(() => {
        passwordEye.value = '&#xe6fb;';
        apikeyEye.value = '&#xe6fb;';
        passwordStorage = password.value = userinfo.password;
        apikey.value = userinfo.apikey;
        apikeyStorage = null;
        jurisdictionTime.value = null;
      }, 600000);
      commonUtils.elmentMessage('验证成功，10分钟后需再次验证', 'info', 30);
    }
    safetyControl.value = false;
  }

  /**
   * 密码输入组件反馈
   * @param {组件返回} params
   */
  async function inputPwdMonitor(params) {
    const userinfo = commonUtils.emptyCookie('Userinfo', null, true);
    if (params.judeg) {
      if (jurisdictionTime.value !== null) {
        loading.value = true;
        const formData = new FormData();
        formData.append('uid', userinfo.uid);
        formData.append('password', await AESUtils.encrypt(params.data, 'stair'));
        axiosApi.routine(apiUrl.value, 'POST', true, formData, false)
          .then(result => {
            if (result === 1) {
              if (passwordEye.value === '&#xe6f9;') {
                passwordStorage = password.value = params.data;
              }
              messageData.value = "修改成功";
              loading.value = false;
            } else {
              messageData.value = "修改失败";
              loading.value = false;
            }
          });
      } else {
        commonUtils.elmentMessage('安全验证已过期', 'warning', 30);
      }
    }
    inputPawControl.value = false;
  }

  /**
   * 显示敏感信息
   * @param {当前按钮属性} judeg
   */
  function viewShow(judeg) {
    const userinfo = commonUtils.emptyCookie('Userinfo');
    if (judeg === '&#xe6fb;') {
      if (!jurisdictionTime.value) {
        safetyControl.value = true;
      } else {
        switch (privacy.value) {
          case 'password':
            if (passwordStorage) {
              password.value = passwordStorage;
            } else {
              pwd_axios(userinfo.uid).then(result => { passwordStorage = password.value = result; });
            }
            passwordEye.value = '&#xe6f9;';
            break;
          case 'apikey':
            if (apikeyStorage) {
              apikey.value = apikeyStorage;
            } else {
              apikey_axios(userinfo.uid).then(result => { apikeyStorage = apikey.value = result; });
            }
            apikeyEye.value = '&#xe6f9;';
            break;
        }
      }
    } else {
      switch (privacy.value) {
        case 'password':
          password.value = userinfo.password;
          passwordEye.value = '&#xe6fb;';
          break;
        case 'apikey':
          apikey.value = userinfo.apikey && userinfo.apikey !== '' ? userinfo.apikey : '未配置';
          apikeyEye.value = '&#xe6fb;';
          break;
      }
    }
  }

  /**
   * 密码获取请求
   * @param {用户id} uid
   */
  function pwd_axios(uid) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('uid', uid);
      axiosApi.routine('user/inquire_password', 'POST', true, formData, false)
        .then(async result => {
          resolve(await AESUtils.decrypt(result, 'stair'));
        });
    });
  }

  /**
   * apikey获取请求
   * @param {用户id} uid
   */
  function apikey_axios(uid) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('uid', uid);
      axiosApi.routine('user/inquire_apikey', 'POST', true, formData, false)
        .then(async result => {
          resolve(result && result !== '' ? await AESUtils.decrypt(result, 'stair') : '未配置');
        });
    });
  }

  /**
   * 复制文本
   * @param {DOM元素} elment
   */
  function copyText(elment) {
    commonUtils.copyText(elment).then(result => {
      messageData.value = result ? "复制成功" : "复制失败";
    })
  }
</script>

<style scoped>
  @import "@/assets/css/userinfo.css";
</style>
