<template>
  <Loading v-if="loadingModule"></Loading>
  <div class="login-base" :style="{ height: baseHeight }">
    <div class="login-superscript"><span class="iconfont" @click="commonUtils.upPage($router)">&#xe619;</span></div>
    <div class="login-outline-box">
      <!--log框-->
      <div class="universal-logo">
        <img src="/static/images/logo/nuyoah_logo_black.png" alt="" draggable="false">
        <div class="universal-circle-breath-one"></div>
        <div class="universal-circle-breath-two"></div>
      </div>
      <h1 class="universal-welcome-speech">Welcome to Nuyoah</h1>
      <!--登录选择框-->
      <div class="login-select-box">
        <div class="login-select">
          <div class="login-select-button-box">
            <button :style="loginStyle.noteLogin" @click="noteControl">短信登录</button>
            <button :style="loginStyle.accountLogin" @click="accountControl">账号登录</button>
          </div>
          <div class="login-line" :style="loginStyle.line"></div>
        </div>
      </div>
      <!--短信框-->
      <form>
        <div class="login-note-box" v-if="loginStyle.noteLoginBox">
          <input class="login-note-input-box" v-model="loginFormData.notePhone" type="text" placeholder="请输入手机号" @keydown.enter="sendLoginAxios">
          <div class="login-note-code-box">
            <input class="login-note-code-input" v-model="loginFormData.noteCode" type="text" placeholder="验证码" @keydown.enter="sendLoginAxios">
            <input class="login-note-code-button" type="button" value="获取验证码">
          </div>
        </div>
        <!--账号框-->
        <div class="login-account-box" v-if="loginStyle.accountLoginBox">
          <input v-model="loginFormData.accountUsername" type="text" placeholder="请输入账号" autocomplete="on" @keydown.enter="sendLoginAxios">
          <div class="login-eye-box">
            <input v-model="loginFormData.accountPassword" :type="accountPasswordType" placeholder="请输入密码" autocomplete="on" @keydown.enter="sendLoginAxios">
            <span class="iconfont login-eyeSwitch" v-html="eyeSwitch" @click="eyeSwitchControl"></span>
          </div>
          <div class="login-account-code-box">
            <input v-model="loginFormData.accountCode" placeholder="请输入验证码" @keydown.enter="sendLoginAxios">
            <img v-if="codeImage != null" :src="codeImage" alt="" draggable="false" @click="imageVerificationCodeAxios">
          </div>
        </div>
      </form>
      <button class="login-button-box" type="button" :disabled="loginButtonSwitch" @click="sendLoginAxios">登录</button>
    </div>
    <!--下栏-->
    <div class="login-botton-box" :style="loginButtonBox">
      <div class="login-botton">
        <div class="login-menu-box">
          <div @click="lock_click"><span class="iconfont" :style="lock">&#xe659;</span>
            <p>记住密码</p>
          </div>
          <div @click="commonUtils.thisSkip(router, '/register')"><span class="iconfont">&#xe716;</span>
            <p>注册账号</p>
          </div>
          <div><span class="iconfont">&#xe8d7;</span>
            <p>忘记密码</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // 常用导入
  import {
    ref,
    onMounted,
    onUnmounted,
    inject
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    // 初始化页面
    bottomButtonMonitor();
    imageVerificationCodeAxios();
    window.addEventListener('resize', handleBodyResize);
  });

  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
  });

  // 常量
  const commonUtils = inject('$commonUtils');
  const axios = inject('$axiosTkxt');
  const cookie = inject('$cookie');
  const AESUtils = inject('$AESUtils');
  const router = useRouter();
  const routePath = inject('$routePath');
  const axiosApi = inject('$axiosApi');

  // 变量
  let windHeight = window.innerHeight;
  let identification = commonUtils.getRandomString(5) + new Date().getTime();
  let device = commonUtils.equipmentJudeg();
  let eyeSwitch = ref('&#xe6fb;');
  let accountPasswordType = ref('password');
  let selectWay = 1;
  let lock = ref({ 'background-color': 'white' });
  let cache = false;
  let loadingModule = ref(null);
  let baseHeight = ref(null);
  let loginButtonSwitch = ref(false);
  let loginButtonBox = ref({ 'bottom': null,'margin-top': null} );
  let codeImage = ref('/static/images/logo/nuyoah_logo_black.png');
  let loginFormData = ref({
    notePhone: '',
    noteCode: '',
    accountUsername: '',
    accountPassword: '',
    accountCode: ''
  });
  let loginStyle = ref({
    noteLogin: { 'color': 'black' },
    accountLogin: { 'color': null },
    line: { 'margin-left': null },
    noteLoginBox: true,
    accountLoginBox: false
  });

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windHeight = window.innerHeight;
    device = commonUtils.equipmentJudeg();
    if (device === 'PC') { bottomButtonMonitor(); }
  }

  /**
   * 监听页面下栏
   */
  function bottomButtonMonitor() {
    baseHeight.value = window.innerHeight + 'px';
    if (windHeight <= 600) {
      loginButtonBox.value['bottom'] = 'inherit';
      loginButtonBox.value['margin-top'] = '12px';
    } else {
      loginButtonBox.value['bottom'] = '40px';
      loginButtonBox.value['margin-top'] = 0;
    }
  }

  /**
   * 登录表单重置
   */
  function resetLoginFormData() {
    loginFormData.value.notePhone = null;
    loginFormData.value.noteCode = null;
    loginFormData.value.accountUsername = null;
    loginFormData.value.accountPassword = null;
  }

  /**
   * 短信登录按钮点击
   */
  function noteControl() {
    resetLoginFormData();
    loginStyle.value.noteLogin['color'] = 'black';
    loginStyle.value.accountLogin['color'] = 'rgb(128, 128, 128)';
    loginStyle.value.line['margin-left'] = '74px';
    loginStyle.value.noteLoginBox = true;
    loginStyle.value.accountLoginBox = false;
    eyeSwitch.value = '&#xe6fb;';
    accountPasswordType.value = 'password';
    selectWay = 1;
  }

  /**
   * 账号密码登录按钮
   */
  function accountControl() {
    loginStyle.value.accountLogin['color'] = 'black';
    loginStyle.value.noteLogin['color'] = 'rgb(128, 128, 128)';
    loginStyle.value.line['margin-left'] = '174px';
    loginStyle.value.accountLoginBox = true;
    loginStyle.value.noteLoginBox = false;
    selectWay = 2;
  }

  // 记住密码按钮
  function lock_click() {
    if (lock.value['background-color'] !== 'rgb(223, 228, 234)') {
      lock.value = { 'background-color': 'rgb(223, 228, 234)' };
      cache = true;
    } else {
      lock.value = { 'background-color': 'white' };
      cache = false;
    }
  }

  /**
   * 密码显示开关
   */
  function eyeSwitchControl() {
    if (eyeSwitch.value === '&#xe6fb;') {
      eyeSwitch.value = '&#xe6f9;';
      accountPasswordType.value = 'text';
    } else {
      eyeSwitch.value = '&#xe6fb;';
      accountPasswordType.value = 'password';
    }
  }

  /**
   * 图片验证码请求
   */
  function imageVerificationCodeAxios() {
    const formData = new FormData();
    formData.append('identification', identification);
    axiosApi.routine('/user/code', 'POST', true, formData, true)
      .then(result => { codeImage.value = 'data:image/png;base64,' + result; })
      .catch(error => { axiosApi.statusPopup(error); });
  }

  /**
   * 发送登录请求
   */
  async function sendLoginAxios() {
    if (selectWay === 1) {
      commonUtils.elmentMessage('短信登录暂不可用', 'info', 20);
      return;
    }
    if (/^.+$/.test(loginFormData.value.accountUsername) && /^.+$/.test(loginFormData.value.accountPassword)) {
      if (!/^.+$/.test(loginFormData.value.accountCode)) {
        commonUtils.elmentMessage('验证码不为空', 'info', 20);
        return;
      }
      loginButtonSwitch.value = true;
      loadingModule.value = true;
      const formData = new FormData();
      formData.append('accountUsername', loginFormData.value.accountUsername);
      formData.append('accountPassword', await AESUtils.encrypt(loginFormData.value.accountPassword, 'stair'));
      formData.append('accountCode', loginFormData.value.accountCode);
      formData.append('identification', identification);
      formData.append('selectWay', selectWay);
      axiosApi.routine('/user/login', 'POST', true, formData, true)
        .then(result => {
          if (result.status === '200') {
            // 判断应该路由至那个页面
            if (routePath.value) {
              commonUtils.thisSkip(router, routePath.value);
            } else {
              commonUtils.thisSkip(router, '/');
            }
            // 设置所需cookie
            cookie.set('Short-Token', result.shortToken);
            cookie.set('Long-Token', result.longToken);
            cookie.set('Userinfo', encodeURI(JSON.stringify(result.userinfo)), cache ?? 0);
            let refresh = cookie.get('Refresh');
            if (refresh) {
              refresh = JSON.parse(refresh);
              for (const key in refresh) {
                refresh[key] = false;
              }
              cookie.set('Refresh', JSON.stringify(refresh));
            } else {
              cookie.set('Refresh', JSON.stringify({}));
            }
            resetLoginFormData();
          } else if (result.status) {
            commonUtils.elmentMessage(result.hint, 'info', 20);
          } else {
            commonUtils.elmentMessage('未知错误', 'info', 20);
          }
          loginButtonSwitch.value = false;
          loadingModule.value = false;
          imageVerificationCodeAxios();
        })
        .catch(error => {
          loginButtonSwitch.value = false;
          loadingModule.value = false;
          axiosApi.statusPopup(error);
        });
    } else {
      commonUtils.elmentMessage('用户名或密码不为空', 'info', 20);
    }
  }
</script>

<style scoped>
  @import "@/assets/css/login.css";
  @import "@/assets/css/universal.css";
</style>
