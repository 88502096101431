<!-- 消息弹窗组件 -->
<template>
  <div class="message-box" v-bind:style="messageStyle" v-if="message">
    <p class="message-p">{{ message }}</p>
  </div>
</template>

<script setup>
  import {
    ref,
    defineProps,
    nextTick,
    watch
  } from 'vue';

  // 常量
  const emits = defineEmits(['update:message']);
  const props = defineProps({
    message: {
      type: String,
      required: false
    },
    left: {
      type: String,
      required: false
    },
    right: {
      type: String,
      required: false
    },
    top: {
      type: String,
      required: false
    },
    bottom: {
      type: String,
      required: false
    },
    zIndex:{
      type: String,
      required: false
    },
    timeout: {
      type: Number,
      required: false
    }
  });

  // 位置变量
  let messageStyle = ref({
    left: props.left ?? "50%",
    right: props.right ?? "auto",
    top: props.top ?? "50%",
    bottom: props.bottom ?? "auto",
    zIndex: props.zIndex ?? 2000
  })
  // 弹窗时间变量
  let timeOut = null
  let time = props.timeout ?? 3000

  /**
   * 初始化定时器函数
   */
  function setTimer() {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      emits('update:message', null);
    }, time);
  }

  /**
   * 监听message参数变化
   */
  watch(() => props.message, (value) => {
    if (value) { setTimer(); }
  });
</script>

<style scoped>

  .message-box {
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    position: absolute;
    z-index: 2000;
  }

  .message-p {
    user-select: none;
    padding: 5px 20px;
    margin: 0px;
    font-size: 15px;
    background-color: white;
    box-shadow: 0 0 5px rgba(53, 59, 72, 0.3);
    color: rgba(72, 84, 96, 1.0);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 5px;
    box-shadow: 0 0 10px grey;
  }
</style>
