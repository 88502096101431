<template>
  <PreviewPicture :images="previewPictureMonitor" :index="previewPictureImageIndex" @previewPictureEmits="previewPictureMonitor = []"></PreviewPicture>
  <div class="picture_generation-mask" v-if="maskControl" @click="leftSwitch"></div>
  <div class="picture_generation-base">
    <!-- 左区域 -->
    <div class="picture_generation-left-frame-box" :style="pictureGeneration.leftBox">
      <div class="picture_generation-left">
        <div class="picture_generation-panel-box">
          <p><el-icon>
              <SetUp />
            </el-icon>参数配置</p>
          <div class="picture_generation-panel-close" v-if="menuSwitchControl" @click.stop="leftSwitch"><el-icon>
              <CloseBold />
            </el-icon> </div>
        </div>
        <div class="picture_generation-option-box">
          <span class="picture_generation-option-describe">模型选择</span>
          <div class="picture_generation-option-value">
            <el-segmented class="picture_generation-option-segmented" v-model="model" :options="models" :disabled="models.disabled" @change="modelControl" />
          </div>
        </div>
        <div class="picture_generation-option-box">
          <span class="picture_generation-option-describe">图片风格</span>
          <div class="picture_generation-option-value">
            <el-segmented class="picture_generation-option-segmented" v-model="stylize" :options="stylizes" :disabled="stylizeJudeg" />
          </div>
        </div>
        <div class="picture_generation-option-box">
          <span class="picture_generation-option-describe">图片大小</span>
          <div class="picture_generation-option-value">
            <el-segmented class="picture_generation-option-segmented" v-model="size" :options="sizes" />
          </div>
        </div>
        <div class="picture_generation-option-box">
          <span class="picture_generation-option-describe">生成数量</span>
          <div class="picture_generation-option-value">
            <el-slider class="pig_quantity" v-model="quantity" :min="1" :max="10" :show-input="true" size="small" :disabled="sizeJudeg" />
          </div>
        </div>
        <div class="picture_generation-option-box">
          <span class="picture_generation-option-describe">图片质量</span>
          <div class="picture_generation-option-quality">
            <el-switch v-model="quality" inline-prompt active-text="HD" inactive-text="NO" size="large" :width="80" :disabled="qualityJudeg" />
          </div>
        </div>
      </div>
      <!-- 用户信息框 -->
      <div class="picture_generation-userinfo-box">
        <User></User>
      </div>
    </div>

    <!-- 右区域 -->
    <div class="picture_generation-right-box">
      <div class="picture_generation-patterns-box">
        <div class="picture_generation-menu-switch" v-if="menuSwitchControl" @click.stop="leftSwitch">
          <el-icon>
            <Operation />
          </el-icon>
        </div>
        <el-segmented v-model="pattern" :options="patterns" @change="topSelectControl">
          <template #default="{ item }">
            <div class="flex flex-col items-center gap-2 p-2">
              <el-icon size="20">
                <component :is="item.icon" />
              </el-icon>
              <div>{{ item.label }}</div>
            </div>
          </template>
        </el-segmented>
      </div>
      <div class="picture_generation-content-box">
        <div class="picture_generation-picture-file" v-if="trustFile" :style="pictureGeneration.pictureFile">
          <div class="picture_generation-picture-box">
            <input ref="selectFileRef" type="file" v-show="false" accept=".png" @change="afterSelectionImage" />
            <button @click.stop="selectFileRef.click()">选择图片</button>
            <p>{{ inputFileName }}</p>
            <div class="picture_generation-file-button" @click.stop="dragUploadingControl">
              <el-icon :style="pictureGeneration.fileButtonIcon">
                <ArrowDownBold />
              </el-icon>
            </div>
          </div>
          <div class="picture_generation-file-box">
            <div class="picture_generation-uploading" :style="pictureGeneration.uploading" v-if="slideImageUploadingControl" @dragover.stop.prevent="dragoverStyle" @dragleave.stop.prevent="dragleaveStyle" @drop.stop.prevent="dropFile" @click.stop="selectFileRef.click()">
              <div>
                <div class="picture_generation-uploading-icon">
                  <el-icon :style="pictureGeneration.icon">
                    <Upload />
                  </el-icon>
                </div>
                <p :style="pictureGeneration.icon">拖动或点击此处上传图片</p>
              </div>
            </div>
            <div class="picture_generation-preview-picture" :style="pictureGeneration.previewPicture">
              <img v-if="inputFileUrl !== null" :src="inputFileUrl" alt="" @click.stop="uploadingPreviewPicture">
            </div>
          </div>
        </div>
        <div class="picture_generation-send-button-box">
          <textarea v-model="pictureeScription" v-if="textareaJudeg" contenteditable="true" placeholder="图片描述..."></textarea>
          <el-button type="default" native-type="button" :loading="imageLoading" @click.stop="sendPictureGenerateAxios">生成图片</el-button>
        </div>
        <div class="picture_generation-exhibition-box">
          <div class="picture_generation-exhibition" v-for="(item, index) in pictureAll">
            <div class="picture_generation-exhibition-download" :style="pictureGeneration.exhibitionDownload" @click.stop="downloadImage(item)">
              <el-icon>
                <Download />
              </el-icon>
            </div>
            <img :src="item.url" alt="" @click.stop="openPreviewPicture(item, index)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs,
    nextTick
  } from 'vue';
  import {
    Picture,
    PictureFilled,
    PictureRounded,
    SetUp,
    CloseBold,
    Operation,
    ArrowDownBold,
    Download,
    Upload
  } from '@element-plus/icons-vue';
  import { useRouter } from 'vue-router';

  /**
   * 组件加载执行
   */
  onMounted(() => {
    pageInitialize();
    commonUtils.addPageIdentification(pageRandom);
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });

  /**
   * 组件卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  // 常量
  const router = useRouter();
  const commonUtils = inject('$commonUtils');
  const axios = inject('$axiosTkxt');
  const axiosApi = inject('$axiosApi');
  const emits = defineEmits();
  const pageRandom = 'PictureGeneration-' + commonUtils.getRandomInteger(10) + commonUtils.getRandomString(5);

  // 变量
  let device = commonUtils.equipmentJudeg();
  let windWidth = window.innerWidth;
  let textareaJudeg = ref(true);
  let imageLoading = ref(false);
  let maskControl = ref(false);
  let menuSwitchControl = ref(false);
  let slideImageUploadingControl = ref(true);
  let pageJudeg = windWidth >= 1000;
  let model = ref('Dall-e-2');
  let sizes = ref(['256x256', '512x512', '1024x1024']);
  let size = ref('512x512');
  let sizeJudeg = ref(false);
  let stylize = ref(null);
  let stylizes = ref(['Vivid', 'Natural']);
  let stylizeJudeg = ref(true);
  let quantity = ref(1);
  let quality = ref(false);
  let qualityJudeg = ref(true);
  let pattern = ref(1);
  let pictureeScription = ref('');
  let trustFile = ref(false);
  let previewPictureImageIndex = ref(0);
  let pictureAll = ref([]);
  let imageType = 'generations';
  let previewPictureMonitor = ref([]);
  let selectFileRef = ref(null);
  let inputFileName = ref(null);
  let inputFileUrl = ref(null);
  let models = ref([{
      label: 'Dall-e-2',
      value: 'Dall-e-2',
      disabled: false
    },
    {
      label: 'Dall-e-3',
      value: 'Dall-e-3',
      disabled: false
    }
  ]);
  let patterns = [{
      label: '图片生成',
      value: 1,
      icon: Picture
    },
    {
      label: '图片编辑',
      value: 2,
      icon: PictureFilled
    },
    {
      label: '图片变化',
      value: 3,
      icon: PictureRounded
    }
  ];
  let pictureGeneration = ref({
    leftBox: { 'width': null, 'min-width': null, 'position': null, 'transition': 'all 0.2s ease-in-out' },
    pictureFile: { 'height': '40px' },
    fileButtonIcon: { 'transform': null },
    previewPicture: { 'width': null },
    uploading: { 'border': '2px dashed gray' },
    icon: { 'color': 'gray', 'transition': 'color 0.3s ease-in-out' },
    exhibitionDownload: device === 'MT' ? { 'bottom': '0', 'background-color': 'rgba(241, 242, 246,0.5)', 'color': 'rgba(255, 255, 255,1.0)' } : null
  });

  /**
   * 页面路由时执行
   */
  router.beforeEach((to, from, next) => {
    handleBeforeUnload();
    next();
  });

  /**
   * 页面刷新或关闭关闭时
   */
  function handleBeforeUnload() {
    commonUtils.removePageIdentification(pageRandom);
  }

  // 监听浏览器页面是否可视
  function visualizationMonitor() {
    commonUtils.addPageIdentification(pageRandom);
  }

  // 监听页面变化
  function handleBodyResize() {
    windWidth = window.innerWidth;
    device = commonUtils.equipmentJudeg();
    pageInitialize();
  }

  /**
   * 页面初始化
   */
  function pageInitialize() {
    if (windWidth >= 1000 && pageJudeg) {
      maskControl.value = false;
      pageJudeg = false;
      menuSwitchControl.value = false;
      slideImageUploadingControl.value = true;
      pictureGeneration.value.previewPicture['width'] = '45%';
      pictureGeneration.value.leftBox['position'] = 'relative';
      pictureGeneration.value.leftBox['width'] = '400px';
      pictureGeneration.value.leftBox['min-width'] = '400px';
    } else {
      if (windWidth <= 1000 && !pageJudeg) {
        pageJudeg = true;
        menuSwitchControl.value = true;
        slideImageUploadingControl.value = false;
        pictureGeneration.value.previewPicture['width'] = '95%';
        pictureGeneration.value.leftBox['position'] = 'absolute';
        pictureGeneration.value.leftBox['width'] = 0;
        pictureGeneration.value.leftBox['min-width'] = 0;
      }
    }
    pictureGeneration.value.leftBox.transition = '';
    pictureGeneration.value.exhibitionDownload = device === 'MT' ? { 'bottom': '0', 'background-color': 'rgba(241, 242, 246,0.5)', 'color': 'rgba(255, 255, 255,1.0)' } : null;
  }

  /**
   * 左侧栏控制按钮
   */
  function leftSwitch() {
    pictureGeneration.value.leftBox.transition = 'all 0.2s ease-in-out';
    if (pictureGeneration.value.leftBox.width === 0) {
      if (windWidth >= 400) {
        maskControl.value = true;
        pictureGeneration.value.leftBox.width = '400px';
        pictureGeneration.value.leftBox['min-width'] = '400px';
      } else {
        pictureGeneration.value.leftBox.width = windWidth + 'px';
        pictureGeneration.value.leftBox['min-width'] = windWidth + 'px';
      }
    } else {
      maskControl.value = false;
      pictureGeneration.value.leftBox.width = 0;
      pictureGeneration.value.leftBox['min-width'] = 0;
    }
  }

  /**
   * 模型按钮控制选项
   */
  function modelControl() {
    if (model.value === 'Dall-e-2') {
      sizeJudeg.value = false;
      stylize.value = null;
      stylizeJudeg.value = true;
      quality.value = false;
      qualityJudeg.value = true;
      size.value = '512x512';
      sizes.value = ['256x256', '512x512', '1024x1024'];
    } else {
      quantity.value = 1;
      sizeJudeg.value = true;
      stylize.value = 'Vivid';
      stylizeJudeg.value = false;
      quality.value = true;
      qualityJudeg.value = false;
      size.value = '1792x1024';
      sizes.value = ['1024x1024', '1792x1024', '1024x1792'];
    }
  }

  /**
   * 托拽上传按钮控制
   */
  function dragUploadingControl() {
    if (pictureGeneration.value.pictureFile.height === '40px') {
      pictureGeneration.value.pictureFile.height = '245px';
      pictureGeneration.value.fileButtonIcon.transform = 'rotate(180deg)';
    } else {
      pictureGeneration.value.pictureFile.height = '40px';
      pictureGeneration.value.fileButtonIcon.transform = null;
    }
  }

  /**
   * 上栏生成类型选择控制
   */
  function topSelectControl() {
    switch (pattern.value) {
      case 1:
        selectFileRef.value.files = null;
        inputFileName.value = null;
        inputFileUrl.value = null;
        trustFile.value = false;
        textareaJudeg.value = true;
        imageType = 'generations';
        models.value[1].disabled = false;
        break;
      case 2:
        textareaJudeg.value = true;
        trustFile.value = true;
        imageType = 'edits';
        models.value[1].disabled = true;
        break;
      case 3:
        textareaJudeg.value = false;
        trustFile.value = true;
        imageType = 'variations';
        models.value[1].disabled = true;
        pictureeScription.value = '';
        break;
    }
    model.value = 'Dall-e-2';
    modelControl();
    size.value = '256x256';
    sizes.value = ['256x256', '512x512', '1024x1024'];
  }

  /**
   * 打开图片预览
   * @param {数据集} item
   * @param {图片点击下标} index
   */
  function openPreviewPicture(item, index) {
    previewPictureImageIndex.value = index;
    previewPictureMonitor.value = pictureAll.value;
  }

  /**
   * 用户上传图片预览
   */
  function uploadingPreviewPicture() {
    previewPictureImageIndex.value = 0;
    previewPictureMonitor.value = [{ url: inputFileUrl.value }];
  }

  /**
   * 选择完图片进行处理
   * @param {event} event
   */
  function afterSelectionImage(event) {
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.startsWith('image/png')) {
        commonUtils.elmentMessage('文件类型必须为.png', 'info', 60);
        return;
      }
      if (event.target.files[0].size > (4 * 1024 * 1024)) {
        commonUtils.elmentMessage('文件大小需小于4MB', 'info', 60);
        return;
      }
      let filePath = event.target.value;
      let startIndex = (filePath.indexOf('\\') >= 0 ? filePath.lastIndexOf('\\') : filePath.indexOf('/'));
      let fileName = filePath.substring(startIndex + 1);
      inputFileName.value = fileName;
      inputFileUrl.value = URL.createObjectURL(event.target.files[0]);
    } else {
      inputFileName.value = null;
      inputFileUrl.value = null;
    }
  }

  /**
   * 拽入样式
   */
  function dragoverStyle() {
    pictureGeneration.value.uploading.border = '2px dashed blue';
    pictureGeneration.value.icon.color = 'blue';
  }

  /**
   * 拽出样式
   */
  function dragleaveStyle() {
    pictureGeneration.value.uploading.border = '2px dashed gray';
    pictureGeneration.value.icon.color = 'gray';
  }

  /**
   * 放下文件后判断信息
   * @param {event} event
   */
  function dropFile(event) {
    pictureGeneration.value.uploading.border = '2px dashed gray';
    pictureGeneration.value.icon.color = 'gray';
    let files = event.dataTransfer.files;
    if (files.length !== 1) {
      commonUtils.elmentMessage('文件限制单个', 'info', 60);
      return;
    }
    if (!files[0].name.endsWith('.png')) {
      commonUtils.elmentMessage('文件类型必须为.png', 'info', 60);
      return;
    }
    // if(!files[0].type.startsWith('image/')){
    //   commonUtils.elmentMessage("文件类型错误", "info", 60)
    //   return;
    // }
    if (files[0].size > (4 * 1024 * 1024)) {
      commonUtils.elmentMessage('文件大小需小于4MB', 'info', 60);
      return;
    }
    selectFileRef.value.files = files;
    let fileReader = new FileReader();
    fileReader.onload = () => {
      inputFileName.value = files[0].name;
      inputFileUrl.value = fileReader.result;
    };
    // 将file对象转化为base64
    fileReader.readAsDataURL(files[0]);
  }

  /**
   * 发送生成图片请求
   */
  function sendPictureGenerateAxios() {
    let userinfo = commonUtils.emptyCookie('Userinfo', '请先登录');
    if (userinfo) {
      if (!userinfo.apikey) {
        commonUtils.elmentMessage('未配置apikey', 'warning', 60);
        return;
      }
      if (imageType !== 'generations' && selectFileRef.value && selectFileRef.value.files.length <= 0) {
        commonUtils.elmentMessage('未选择上传文件', 'warning', 60);
        return;
      }
      if (/^.+$/.test(pictureeScription.value) || imageType === 'variations') {
        imageLoading.value = true;
        let formData = new FormData();
        formData.append('uid', userinfo.uid);
        formData.append('username', userinfo.username);
        formData.append('content', pictureeScription.value);
        formData.append('params', JSON.stringify({
          requestType: 'custom',
          model: model.value.toLowerCase(),
          style: stylize.value ? stylize.value.toLowerCase() : null,
          n: quantity.value,
          size: size.value,
          quality: quality.value ? 'hd' : null
        }));
        formData.append('multipartFile', selectFileRef.value ? selectFileRef.value.files[0] : null);
        axiosApi.routine('/chatgpt/image/' + imageType, 'POST', true, formData, false)
          .then(result => {
            if (result.status === 20000) {
              pictureAll.value = JSON.parse(result.data);
            } else {
              commonUtils.elmentMessage(result.error, 'warning', 20);
            }
            imageLoading.value = false;
          })
          .catch(error => {
            imageLoading.value = false;
            axiosApi.statusPopup(error);
          });
      } else {
        commonUtils.elmentMessage('图片描述不为空', 'info', 20);
      }
    }
  }

  /**
   * 点击下载图片
   * @param {数据集} item
   */
  function downloadImage(item) {
    let elem = document.createElement('a');
    // 这里是将url转成blob地址
    fetch(item.url, { mode: 'no-cors' }).then(res => res.blob()).then(blob => {
      let url = window.URL.createObjectURL(blob);
      elem.href = url;
      elem.style.display = 'none';
      elem.download = commonUtils.getRandomString(5) + new Date().getTime() + '.png';
      document.body.appendChild(elem);
      elem.click();
      window.URL.revokeObjectURL(url);
    }).catch((error) => commonUtils.elmentMessage('图片下载失败：' + error, 'error', 20));
  }
</script>

<style scoped>
  @import "@/assets/css/PictureGeneration.css";

  .picture_generation-option-segmented {
    font-size: 12px;
    width: 100%;
    background-color: rgba(206, 214, 224, 0.5);
    --el-segmented-item-selected-color: white;
    --el-segmented-item-selected-bg-color: rgba(119, 139, 235, 1.0);
    --el-border-radius-base: 3px;
  }
</style>
