<template>
  <Loading v-if="loadingModule"></Loading>
  <Inquiry v-if="inquiryData" :data="inquiryData" @inquiryEmits="inquiryMonitor"></Inquiry>
  <!-- 返回上层 -->
  <div class="register-superscript"><span class="iconfont" @click="commonUtils.upPage($router)">&#xe619;</span></div>
  <!--注册框-->
  <form>
    <div class="register-holistic-box">
      <div class="register-holistic">
        <p class="register-holistic-p-box">注册账号</p>
        <div class="register-input-box">
          <input class="register-input" v-model="registerFormData.username" type="text" placeholder="请输入用户名或手机号" @keydown.enter="sendRegisterAxios">
          <div class="register-eye-box">
            <input class="register-input register-input-copy" v-model="registerFormData.password" :type="passwordType" placeholder="请输入密码" autocomplete @keydown.enter="sendRegisterAxios">
            <span class="iconfont register-eyeSwitch" v-html="eyeSwitch" @click="eyeSwitchControl"></span>
          </div>
          <input class="register-input" v-model="registerFormData.affirmPwd" :type="passwordType" placeholder="确认密码" autocomplete @keydown.enter="sendRegisterAxios">
          <div class="register-code-box">
            <input class="register-code" v-model="registerFormData.code" type="text" maxlength="4" placeholder="验证码" @keydown.enter="sendRegisterAxios">
            <img class="register-button" v-if="codeImage != null" :src="codeImage" alt="" draggable="false" @click="imageVerificationCodeAxios">
          </div>
          <button class="register-button-box" type="button" :disabled="registerButtonSwitch" @click="sendRegisterAxios">注册</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
  // 常用导入
  import {
    ref,
    onMounted,
    inject,
    toRefs
  } from 'vue';
  import { useRouter } from 'vue-router';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    imageVerificationCodeAxios();
  });

  // 常量
  const commonUtils = inject('$commonUtils');
  const axios = inject('$axiosTkxt');
  const AESUtils = inject('$AESUtils');
  const router = useRouter();
  const axiosApi = inject('$axiosApi');

  // 变量
  let identification = commonUtils.getRandomString(5) + new Date().getTime();
  let eyeSwitch = ref('&#xe6fb');
  let passwordType = ref('password');
  let codeImage = ref('/static/images/logo/nuyoah_logo_black.png');
  let loadingModule = ref(false);
  let inquiryData = ref(null);
  let registerButtonSwitch = ref(false);
  let registerFormData = ref({
    username: null,
    password: null,
    affirmPwd: null,
    code: null
  });

  /**
   * 密码显示隐藏开关
   */
  function eyeSwitchControl() {
    if (eyeSwitch.value === '&#xe6fb') {
      eyeSwitch.value = '&#xe6f9';
      passwordType.value = 'text';
    } else {
      eyeSwitch.value = '&#xe6fb';
      passwordType.value = 'password';
    }
  }

  /**
   * 图片验证码请求
   */
  function imageVerificationCodeAxios() {
    const formData = new FormData();
    formData.append('identification', identification);
    axiosApi.routine('/user/code', 'POST', true, formData, true)
      .then(result => { codeImage.value = 'data:image/png;base64,' + result; })
      .catch(error => { axiosApi.statusPopup(error); });
  }

  /**
   * 发送注册请求
   */
  async function sendRegisterAxios() {
    // 表单验证
    const username_test = /^[A-Za-z0-9]{6,12}$/;
    const password_test = /^[A-Za-z0-9!@#$%^&*()-_=+<>?]{6,15}$/;
    const empty_test = /^.+$/;
    if (!username_test.test(registerFormData.value.username)) {
      commonUtils.elmentMessage('用户名格式为6-12位英文数字', 'info', 20);
      return;
    }
    if (!password_test.test(registerFormData.value.password)) {
      commonUtils.elmentMessage('密码格式为6-15位英文数字包含英文特殊字符', 'info', 20);
      return;
    }
    if (registerFormData.value.affirmPwd !== registerFormData.value.password) {
      commonUtils.elmentMessage('两次密码输入不同', 'info', 20);
      return;
    }
    if (!empty_test.test(registerFormData.value.code)) {
      commonUtils.elmentMessage('验证码不为空', 'info', 20);
      return;
    }
    // 发送请求
    registerButtonSwitch.value = true;
    loadingModule.value = true;
    const formData = new FormData();
    formData.append('username', registerFormData.value.username);
    formData.append('password', await AESUtils.encrypt(registerFormData.value.password, 'stair'));
    formData.append('code', registerFormData.value.code);
    formData.append('identification', identification);
    axiosApi.routine('/user/register', 'POST', true, formData, true)
      .then(result => {
        if (result.status === '200') {
          inquiryData.value = { message: '注册成功，是否跳转登录' };
        } else if (result.status) {
          commonUtils.elmentMessage(result.hint, 'info', 20);
        } else {
          commonUtils.elmentMessage('未知错误', 'info', 20);
        }
        imageVerificationCodeAxios();
        registerButtonSwitch.value = false;
        loadingModule.value = false;
      })
      .catch(error => {
        registerButtonSwitch.value = false;
        loadingModule.value = false;
        axiosApi.statusPopup(error);
      });
  }

  /**
   * 询问框监听点击
   * @param {子组件传值} params
   */
  function inquiryMonitor(params) {
    if (params.judeg) {
      commonUtils.thisSkip(router, '/login');
    } else {
      inquiryData.value = null;
    }
  }
</script>

<style scoped>
  @import "@/assets/css/register.css";
</style>
