<!-- 图片预览组件 -->
<template>
  <div class="preview_picture-shade" v-if="images.length > 0">
    <div class="preview_picture-selector-box" style="left: 5px;" v-if="images.length > 1">
      <span class="preview_picture-selector" @click.stop="leftSelector">
        <el-icon>
          <ArrowLeftBold />
        </el-icon>
      </span>
    </div>
    <div class="preview_picture-images-box">
      <img v-bind:src="images[imageIndex].url" alt="">
    </div>
    <div class="preview_picture-selector-box" style="right: 5px;" v-if="images.length > 1">
      <span class="preview_picture-selector" @click.stop="rightSelector">
        <el-icon>
          <ArrowRightBold />
        </el-icon>
      </span>
    </div>
    <div class="preview_picture-close" @click.stop="previewPictureClose">
      <span class="iconfont">&#xe65d;</span>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    defineProps,
    defineEmits,
    watch
  } from 'vue';
  import {
    ArrowLeftBold,
    ArrowRightBold
  } from '@element-plus/icons-vue';

  // 常量
  const emits = defineEmits();
  const props = defineProps({
    index: {
      type: Number,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  });

  // 变量
  let imageIndex = ref(props.index);

  /**
   * 监听图片索引变化
   */
  watch(() => props.index, () => { imageIndex.value = props.index; });

  /**
   * 左侧点击上一张
   */
  function leftSelector() {
    console.log(props.images);
    if (imageIndex.value !== 0) {
      imageIndex.value--;
    } else {
      imageIndex.value = props.images.length - 1;
    }
  }

  /**
   * 右侧点击下一张
   */
  function rightSelector() {
    if (imageIndex.value !== props.images.length - 1) {
      imageIndex.value++;
    } else {
      imageIndex.value = 0;
    }
  }

  /**
   * 关闭图片预览
   */
  function previewPictureClose() {
    imageIndex.value = props.index;
    emits('previewPictureEmits');
  }
</script>

<style scoped>
  .preview_picture-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-size: cover;
    backdrop-filter: blur(20px);
    background-color: rgba(47, 53, 66, 0.8);
  }

  .preview_picture-selector-box {
    position: absolute;
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview_picture-selector {
    width: 100%;
    height: 100px;
    margin: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(241, 242, 246, 0.3);
  }

  .preview_picture-selector:active {
    background-color: rgba(241, 242, 246, 0.5);
  }

  .preview_picture-images-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview_picture-images-box img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .preview_picture-close {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(241, 242, 246, 0.1);
  }

  .preview_picture-close:active {
    background-color: rgba(241, 242, 246, 0.3);
  }

  .preview_picture-close span {
    margin: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: white;
  }
</style>
