// 常用js函数工具
import { inject } from 'vue';
import axiosApi from '@/assets/js/axiosApi.js';
import cookie from 'js-cookie';
import clipboard from 'clipboard';
import { ElMessage, ElNotification } from 'element-plus';

const commonUtils = {

  /**
   * 获取url前缀
   */
  getUrlPrefix() { return window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]; },

  /**
   * 返回上页
   * @param {router} router
   */
  upPage(router) { router.go(-1); },

  /**
   * 返回下页
   * @param {router} router
   */
  nextPage(router) { router.go(1); },

  /**
   * 本页跳转
   * @param {router} router
   * @param {路劲} url
   */
  thisSkip(router, url) { router.push(url); },

  /**
   * 新页面跳转
   * @param {路劲} url
   */
  newSkip(url) { window.open(url); },

  /**
   * 获取指定范围整数随机数
   * @param {最小数} min
   * @param {最大数} max
   */
  getRandom(min, max) { return Math.floor(Math.random() * (max - min + 1)) + min; },

  /**
   * 判断当前运行的设备信息
   */
  equipmentJudeg() {
    let judegDevice;
    const userAgentToLowerCase = navigator.userAgent.toLowerCase();
    if (/(android|webos|iphone|ipod|blackberry|iemobile|opera mini)/i.test(userAgentToLowerCase)) {
      judegDevice = 'MT';
    } else {
      judegDevice = 'PC';
    }
    return judegDevice;
  },

  /**
   * 判断页面是否导入css
   * @param {Object} hrefUrl
   */
  whetherLoadCss(hrefUrl) {
    if (this.equipmentJudeg() === 'PC') {
      this.loadCss(hrefUrl);
    } else {
      this.removeCss(hrefUrl);
    }
  },

  /**
   * 载入css文件
   * @param {文件路劲} hrefUrl
   */
  loadCss(hrefUrl) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = hrefUrl;
    // 将链接元素添加到页面头部
    document.head.appendChild(link);
  },

  /**
   * 删除css文件
   * @param {文件路劲} hrefUrl
   */
  removeCss(hrefUrl) {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      if (links[i].href && links[i].href.indexOf(hrefUrl) !== -1) {
        links[i].parentNode.removeChild(links[i]);
      }
    }
  },

  /**
   * 获取字符串随机数
   * @param {长度} length
   */
  getRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  },

  /**
   * 获取随机数
   * @param {长度} length
   */
  getRandomInteger(length) {
    let result = '';
    for (let i = 0; i < length; i++) {
      result += Math.floor(Math.random() * (9 - 0 + 1)) + 0;
    }
    return result;
  },

  /**
   * 字符串替换(手动)
   * @param {开始索引} start
   * @param {结束索引} stop
   * @param {字符串} str
   * @param {替换字符} replaceStr
   */
  substitution(start, stop, str, replaceStr) {
    let newStr = '';
    if(str.length >= stop){
      for (let i = 0; i < str.length; i++) {
        if (i >= start && i <= stop) {
          newStr += replaceStr;
        } else {
          newStr += str[i];
        }
      }
    }else{
      newStr = this.substitutionAuto(str, replaceStr, 10)
    }
    return newStr;
  },

  /**
   * 字符串替换(自动替换中)
   * @param {字符串} str
   * @param {替换字符} replaceStr
   */
  substitutionAuto(str, replaceStr, length) {
    let newStr = '';
    let len = length ?? str.length;
    for (let i = 0; i < len; i++) {
      newStr += replaceStr;
    }
    return newStr;
  },

  /**
   * 登录获取判断
   * @param {cookie名称} name
   * @param {是否刷新页面} refreshJudeg
   * @param {提示消息(可为空)} message
   */
  emptyCookie(name,message, refresh) {
    const data = cookie.get(name);
    if (data) {
      return JSON.parse(decodeURI(cookie.get(name)));
    } else {
      if (message) {
        this.elmentMessage(message, 'info', 60);
      }
      if (refresh) {
        this.userLogout();
      }
      return false;
    }
  },

  /**
   * 退出登录
   */
  userLogout() {
    cookie.remove('Userinfo');
    cookie.remove('Dialogue');
    let refresh = cookie.get('Refresh');
    if (refresh) {
      refresh = JSON.parse(refresh);
      for (const key in refresh) {
        refresh[key] = true;
      }
      cookie.set('Refresh', JSON.stringify(refresh));
    }
    // axiosApi.routine('/user/closeLg', 'POST', true, null, true)
    window.location.reload();
  },

  /**
   * element UI弹出封装
   * @param {消息文本} message
   * @param {弹窗类型} type
   * @param {页面位置} offset
   * @param {是否合并相同消息} grouping
   * @param {文字是否居中} center
   * @param {自定义class属性} customClass
   */
  elmentMessage(message, type, offset, grouping, center, customClass) {
    ElMessage({
      'message': message,
      'type': type,
      'offset': offset ?? 0,
      'grouping': grouping ?? true,
      'center': center ?? true,
      'custom-class': customClass
    });
  },

  /**
   * element UI消息通知封装
   * @param {通知标题} title
   * @param {通知消息} message
   * @param {通知类型} type
   * @param {通知时间} time
   * @param {页面图层} zIndex
   */
  elmentInform(title, message, type, time, zIndex) {
    ElNotification({
      title: title,
      message: message,
      type: type,
      duration: time ?? 3000,
      zIndex: zIndex ?? 2000
    });
  },

  /**
   * 为页面添加标识
   * @param {页面唯一标识} pageName
   */
  addPageIdentification(pageName) {
    if (!document.hidden) {
      let userinfo = this.emptyCookie('Userinfo');
      let refresh = cookie.get('Refresh');
      if (refresh) {
        refresh = JSON.parse(refresh);
        if (refresh[pageName]) {
          refresh[pageName] = false;
          cookie.set('Refresh', JSON.stringify(refresh));
          window.location.reload();
        } else {
          if (userinfo) {
            cookie.set('Refresh', JSON.stringify(Object.assign({}, refresh, {
              [pageName]: false })));
          }
        }
      } else {
        if (userinfo) {
          cookie.set('Refresh', JSON.stringify({
            [pageName]: false
          }));
        }
      }
    }
  },

  /**
   * 删除页面标识
   * @param {页面唯一标识} pageName
   */
  removePageIdentification(pageName) {
    let refresh = cookie.get('Refresh');
    if (refresh) {
      refresh = JSON.parse(refresh);
      if (refresh[pageName] !== null) {
        delete refresh[pageName];
      }
      cookie.set('Refresh', JSON.stringify(refresh));
    }
  },

  /**
   * 复制文本
   * @param {DOM元素} element
   */
  copyText(element) {
    return new Promise((resolve, reject) => {
      let clipboardJS = new clipboard(element);
      clipboardJS.on('success', function(e) {
        e.clearSelection();
        resolve(true);
      });
      clipboardJS.on('error', function(e) {
        resolve(false);
      });
    });
  }
};

export default commonUtils;
