// axios封装工具
import commonUtils from '@/assets/js/commonUtils.js';
import axios from 'axios';
import cookie from 'js-cookie';

const axiosPg = axios.create({ baseURL: '/nuyoah', timeout: 100000 });
const axiosApi = {
  /**
   * 常规axios封装
   * @param {请求路劲} url
   * @param {请求类型} method
   * @param {是否异步} async
   * @param {请求参数} formData
   * @param {是否需要登录} judeg
   */
  routine (url, method, async, formData, judeg) {
    return new Promise((resolve, reject) => {
      const hint = judeg ? null : '请先登录';
      const userinfo = commonUtils.emptyCookie('Userinfo', hint);
      if (!formData) { formData = new FormData(); }
      formData.append('uToken', userinfo.uid);
      if (userinfo || judeg) {
        axiosPg({
          url,
          method,
          async,
          data: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'multipart/form-data',
            'Short-Token': cookie.get('Short-Token'),
            'Long-Token': cookie.get('Long-Token')
          }
        }).then(result => {
          if (result.status === 200) {
            const shortToken = result.headers['short-token'];
            if (shortToken !== null && shortToken !== undefined) {
              cookie.set('Short-Token', shortToken);
            }
            const longToken = result.headers['long-token'];
            if (longToken !== null && longToken !== undefined) {
              cookie.set('Long-Token', longToken);
            }
            resolve(result.data);
          } else {
            reject(null);
            commonUtils.elmentMessage('请求出错', 'error', 60);
          }
        }).catch(error => {
          reject(error);
          this.statusPopup(error);
        });
      }
    });
  },

  /**
   * 请求错误代码弹窗内容
   * @param {错误信息} error
   */
  statusPopup (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          commonUtils.elmentMessage('登录失效', 'error', 20);
          break;
        case 403:
          commonUtils.elmentMessage('禁止访问', 'error', 20);
          break;
        case 429:
          commonUtils.elmentMessage('请求速率过快', 'error', 20);
          break;
        default:
          commonUtils.elmentMessage('内部错误: ' + error, 'error', 20);
          break;
      }
    } else {
      commonUtils.elmentMessage('未知错误: ' + error, 'error', 20);
    }
  }

};

export default axiosApi;
