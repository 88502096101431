<!-- 选择框组件 -->
<template>
  <div class="select-shade">
    <div class="select-shade-box">
      <div class="select-close-box">
        <span class="select-hint">{{ data.title }}</span>
        <div class="select-close" @click="cancelSubmit">
          <span class="iconfont">&#xe65d;</span>
        </div>
      </div>
      <div ref="selectSlideRef" class="select-slide-box" @scroll="immobilizationScroll" @wheel.passive="fixationScrollTop">
        <div :style="{ 'font-size': data.fontSize }">
          <div class="select-top-box"></div>
          <div class="select-center-box" v-for="(item, index) in seleAll" :style="item.active" @click="selectClick(item, index)">{{ item.select }}</div>
          <div class="select-bottom-box"></div>
        </div>
      </div>
      <div class="select-submit-box">
        <button class="select-cancel" @click="cancelSubmit">取消</button>
        <button class="select-affirm" @click="confirmSubmit">确定</button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    inject,
    onMounted,
    defineProps,
    defineEmits,
    nextTick
  } from 'vue';

  /**
   * 页面载入执行
   */
  onMounted(() => {
    nextTick(() => {
      initialize();
    });
  });

  // 常量
  const emits = defineEmits();
  const selectSlideRef = ref(null);
  const result = ref(0);
  const seleAll = ref([]);
  const props = defineProps({
    data: {
      type: Object,
      required: true
    }
  });

  /**
   * 提交选择值
   */
  function confirmSubmit() {
    emits('selectEmits', {
      judeg: true,
      data: seleAll.value[result.value].select
    });
  }

  /**
   *关闭选择框
   */
  function cancelSubmit() {
    emits('selectEmits', {
      judeg: false
    });
  }

  /**
   * 初始化参数
   */
  function initialize() {
    for (let i = 0; i < props.data.selAll.length; i++) {
      if (i === 0) {
        seleAll.value.push({ select: props.data.selAll[i], active: { color: 'white', 'font-weight': 'bold', transform: 'scale(1.0)', 'background-color': 'rgba(53, 59, 72, 0.8)' } });
      } else {
        seleAll.value.push({ select: props.data.selAll[i], active: null });
      }
    }
  }

  /**
   * 固定滑动值避免错乱
   */
  function immobilizationScroll() {
    seleAll.value[result.value].active = null;
    result.value = Math.round(selectSlideRef.value.scrollTop / 40);
    seleAll.value[result.value].active = { color: 'white', 'font-weight': 'bold', transform: 'scale(1.0)', 'background-color': 'rgba(53, 59, 72, 0.8)' };
  }

  /**
   * 点击选择
   * @param {数据集} item
   * @param {索引} index
   */
  function selectClick(item, index) {
    selectSlideRef.value.scrollTop = index * 40;
  }

  /**
   * 滑动监听
   * @param {} e
   */
  function fixationScrollTop(e) {
    selectSlideRef.value.scrollTop += e.deltaY > 0 ? 40 : -40;
  }
</script>

<style scoped>
  .select-shade {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(30, 39, 46, 0.5);
    z-index: 2000;
  }

  .select-shade-box {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 300px;
    height: 180px;
    background-color: white;
    box-shadow: 0 0 15px rgba(53, 59, 72, 0.5);
  }

  .select-close-box {
    height: 20%;
    display: flex;
    align-items: center;
    background-color: rgba(53, 59, 72, 1.0);
  }

  .select-hint {
    max-width: 70%;
    user-select: none;
    color: white;
    font-size: 15px;
    margin-left: 20px;
    color: rgba(220, 221, 225, 0.8);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select-close {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .select-close:active {
    background-color: rgba(220, 221, 225, 0.2);
  }

  .select-close span {
    user-select: none;
    font-size: 17px;
    color: rgba(241, 242, 246,0.9);
  }

  .select-slide-box {
    text-align: center;
    user-select: none;
    position: relative;
    height: 55%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .select-slide-box::-webkit-scrollbar {
    display: none;
  }

  .select-top-box {
    min-height: 30px;
  }

  .select-bottom-box {
    min-height: 29px;
  }

  .select-center-box {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 20px;
    max-width: 80%;
    height: 40px;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.6);
    background-color: rgba(245, 246, 250, 1.0);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    white-space: nowrap;
  }

  .select-submit-box {
    user-select: none;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .select-submit-box button {
    width: 35%;
    height: 70%;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .select-affirm {
    border: none;
    background-color: rgba(0, 168, 255, 0.8);
    color: white;
  }

  .select-affirm:active {
    background-color: rgba(0, 168, 255, 0.5);
  }

  .select-cancel {
    border: none;
    background-color: rgba(225, 177, 44, 0.8);
    color: white;
  }

  .select-cancel:active {
    background-color: rgba(225, 177, 44, 0.5);
  }
</style>
