<!-- loading加载组件 -->
<template>
  <div class="loading-parent-box" :style="loadingStyle">
    <svg class="loading-scalable-box" viewBox="0 0 128 256">
      <defs>
        <linearGradient id="ap-grad1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stop-color="hsl(223,90%,55%)" />
          <stop offset="100%" stop-color="hsl(253,90%,55%)" />
        </linearGradient>
        <linearGradient id="ap-grad2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stop-color="hsl(193,90%,55%)" />
          <stop offset="50%" stop-color="hsl(223,90%,55%)" />
          <stop offset="100%" stop-color="hsl(253,90%,55%)" />
        </linearGradient>
      </defs>
      <circle class="loading-apring-box" r="56" cx="64" cy="192" fill="none" stroke="#ddd" stroke-width="16" stroke-linecap="round" />
      <circle class="loading-apworm-one-box" r="56" cx="64" cy="192" fill="none" stroke="url(#ap-grad1)" stroke-width="16" stroke-linecap="round" stroke-dasharray="87.96 263.89" />
      <path class="loading-apworm-two-box" d="M120,192A56,56,0,0,1,8,192C8,161.07,16,8,64,8S120,161.07,120,192Z" fill="none" stroke="url(#ap-grad2)" stroke-width="16" stroke-linecap="round" stroke-dasharray="87.96 494" />
    </svg>
  </div>
</template>

<script setup>
  import {
    ref,
    defineProps,
  } from 'vue';

  // 常量
  const props = defineProps({
    left: {
      type: String,
      required: false
    },
    right: {
      type: String,
      required: false
    },
    top: {
      type: String,
      required: false
    },
    bottom: {
      type: String,
      required: false
    },
    zIndex: {
      type: String,
      required: false
    },
  });

  // 位置变量
  let loadingStyle = ref({
    left: props.left ?? "50%",
    right: props.right ?? "auto",
    top: props.top ?? "50%",
    bottom: props.bottom ?? "auto",
    zIndex: props.zIndex ?? 2000
  })
</script>

<style scoped>
  .loading-parent-box {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2000;
  }

  .loading-scalable-box {
    width: 40px;
    height: 70px;
  }

  .loading-apring-box {
    transition: stroke 0.3s;
  }

  .loading-apworm-one-box,
  .loading-apworm-two-box {
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .loading-apworm-two-box {
    animation-name: worm2;
    visibility: hidden;
  }

  .loading-apworm-one-box {
    animation-name: worm1;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --bg: hsl(var(--hue), 10%, 10%);
      --fg: hsl(var(--hue), 10%, 90%);
    }

    .loading-apring-box {
      stroke: hsla(var(--hue), 10%, 90%, 0.9);
    }
  }

  @keyframes worm1 {
    from {
      animation-timing-function: ease-in-out;
      stroke-dashoffset: -87.96;
    }

    20% {
      animation-timing-function: ease-in;
      stroke-dashoffset: 0;
    }

    60% {
      stroke-dashoffset: -791.68;
      visibility: visible;
    }

    60.1%,
    to {
      stroke-dashoffset: -791.68;
      visibility: hidden;
    }
  }

  @keyframes worm2 {

    from,
    60% {
      stroke-dashoffset: -87.96;
      visibility: hidden;
    }

    60.1% {
      animation-timing-function: cubic-bezier(0, 0, 0.5, 0.75);
      stroke-dashoffset: -87.96;
      visibility: visible;
    }

    77% {
      animation-timing-function: cubic-bezier(0.5, 0.25, 0.5, 0.88);
      stroke-dashoffset: -340;
      visibility: visible;
    }

    to {
      stroke-dashoffset: -669.92;
      visibility: visible;
    }
  }
</style>
