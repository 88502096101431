import { createStore } from 'vuex';

const store = createStore({
  state: {
    jurisdictionTime: 1
  },
  getters: {
    getJurisdictionTime(state) {
      return state.jurisdictionTime;
    }
  },
  mutations: {
    setJurisdictionTime(state, value) {
      state.jurisdictionTime = value;
    }
  },
  actions: {
    setJurisdictionTimeAction({ commit }, value) {
      commit('setJurisdictionTime', value);
    }
  },
  modules: {}
});

export default store;
