<template>
  <div class="home-base">
    <!--上栏-->
    <el-carousel class="home-trotting_horseLamp-box" :height="trottingHeight" :type="trottingCard" :trigger="dropdownType" :interval="3000">
      <el-carousel-item v-for="(item, index) in trottingHorseLamp">
        <div class="home-trotting-box">
          <img class="home-trotting-img" v-bind:src="item" draggable="false" alt="" />
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 文章上选择框 -->
    <div class="home-article-top-box">

    </div>

    <div class="home-article-box">
      <div class="home-article-left-box">

        <div class="home-article-image-box">
          <div class="home-article-image">
            <img src="@/assets/image/rests/11.png" draggable="false" alt="" />
          </div>
          <div class="home-article-content-box">
            <div class="home-article-title">
              <h3>项目介绍</h3>
            </div>
            <div class="home-article-details">
              <p>主页还未开发，上栏chatgpt页面，图片下的图片生成页面，用户界面可正常使用！</p>
            </div>
            <div class="home-article-meun-box">
              <div class="home-article_user">
                <span>作者：</span>
                <p>开发者大大</p>
              </div>
              <div class="home-article-interaction-box">
                <span class="iconfont">&#xe8ad;</span>
                <span class="iconfont home-article-trample">&#xe8ad;</span>
                <span class="iconfont">&#xe607;</span>
              </div>
              <el-dropdown :trigger="dropdownType">
                <span class="home-el-dropdown-link">
                  <el-icon>
                    <MoreFilled />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>测试</el-dropdown-item>
                    <el-dropdown-item>测试</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>

      </div>

      <div class="home-article-right-box" v-if="false" v-bind:style="homeRightWidth">

      </div>
    </div>

    <!--备案号悬挂-->
    <div class="home-archival_information-box">
      <div class="home-archival_information">
        <p>ICP备案号：</p><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024044669号-1</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {
    ref,
    onMounted,
    onUnmounted,
    inject,
    toRefs
  } from 'vue';
  import { useRouter } from 'vue-router';
  import {
    MoreFilled,
    StarFilled
  } from '@element-plus/icons-vue';

  /**
   * 页面加载执行
   */
  onMounted(() => {
    pageInitialize();
    commonUtils.addPageIdentification(pageRandom);
    window.addEventListener('resize', handleBodyResize);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('visibilitychange', visualizationMonitor);
  });
  /**
   * 页面卸载执行
   */
  onUnmounted(() => {
    window.removeEventListener('resize', handleBodyResize);
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('visibilitychange', visualizationMonitor);
  });

  //常量
  const router = useRouter();
  const cookie = inject('$cookie');
  const commonUtils = inject('$commonUtils');
  const pageRandom = 'Home-' + commonUtils.getRandomInteger(10) + commonUtils.getRandomString(5);

  // 变量
  let device = commonUtils.equipmentJudeg();
  let windWidth = window.innerWidth;
  let trottingCard = ref(null);
  let trottingHeight = ref(null);
  let dropdownType = ref(null);
  let homeRightWidth = ref({ 'width': 0, 'min-width': 0 });
  let trottingHorseLamp = ref([
    require('@/assets/image/trottingHorseLamp/1.jpg'),
    require('@/assets/image/trottingHorseLamp/2.jpg'),
    require('@/assets/image/trottingHorseLamp/3.jpg'),
    require('@/assets/image/trottingHorseLamp/4.jpg'),
    require('@/assets/image/trottingHorseLamp/5.jpg')
  ]);

  /**
   * 页面路由时执行
   */
  router.beforeEach((to, from, next) => {
    handleBeforeUnload();
    next();
  });

  /**
   * 页面刷新或关闭时执行
   */
  function handleBeforeUnload() {
    commonUtils.removePageIdentification(pageRandom);
  }

  /**
   * 监听浏览器页面是否可视
   */
  function visualizationMonitor() {
    commonUtils.addPageIdentification(pageRandom);
  }

  /**
   * 页面大小变化执行操作
   */
  function handleBodyResize() {
    windWidth = window.innerWidth;
    device = commonUtils.equipmentJudeg();
    pageInitialize();
  }

  /**
   * 页面大小变化执行操作
   */
  function pageInitialize() {
    let subtrahend = 0;
    if (windWidth > 1920) {
      subtrahend = 5;
    } else if (windWidth >= 800) {
      subtrahend = 0;
      trottingCard.value = 'card'
      homeRightWidth.value['width'] = '300px';
      homeRightWidth.value['min-width'] = '300px';
    } else {
      subtrahend = 2;
      trottingCard.value = null
      homeRightWidth.value['width'] = 0;
      homeRightWidth.value['min-width'] = 0;
    }
    dropdownType.value = device === "PC" ? 'click' : 'hover';
    trottingHeight.value = windWidth / (4 - subtrahend) + 'px';
  }
</script>

<style scoped>
  @import "@/assets/css/home.css";
</style>
